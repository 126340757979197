<template>
  <div class="register">
    <div class="bar">
      <div @click="onClickLeft" class="box"><van-icon color="#fff" size="20" class="return" name="arrow-left" /></div>
      <div class="user">{{$t('USERREGISTER')}}</div>
    </div>
    <!-- <div class="adv">
      <van-image :src="require('./img/photo_2023-05-18_19-56-37.jpg')" height="150" width="150"></van-image>
    </div> -->
    <van-form class="formt" @submit="onSubmit">
      <van-field
        v-model="phoneNum"
        name="phoneNum"
        :placeholder="$t('INPUTPHONE')"
        class="area-container"
      >
        <div slot="label" class="whitecolor">
          {{$t('CONTACTPHONE')}}
          <!-- <span class="area" @click="areaCodeShow = true">+{{areaCode.code}}</span> -->
        </div>
      </van-field>
      <van-field
        v-model="username"
        name="username"
        :placeholder="$t('USERNAME')"
      >
        <div slot="label" class="whitecolor">{{$t('USERNAME')}}</div>
      </van-field>
      <van-field
        v-model="password"
        type="password"
        name="password"
        :placeholder="$t('PASSWORD')"
      >
        <div slot="label" class="whitecolor">{{$t('PASSWORD')}}</div>
      </van-field>
      <van-field
        v-model="checkpassword"
        type="password"
        name="checkpassword"
        :placeholder="$t('CHECKPASSWORDRe')"
      >
        <div slot="label" class="whitecolor">{{$t('CHECKPASSWORDRe')}}</div>
      </van-field>
      <!-- <van-field
        v-model="sms"
        name="message"
        :placeholder="$t('INPUTNOTE')"
      >
        <template #button>
          <van-button v-if="empty" @click="sendAuthCode" native-type="button" class="yanzhengma" size="small" type="warning">{{$t('SEND_SMS')}}</van-button>
          <van-count-down @finish="changeEmpty" ref="countDown" v-else :time="time">
            <template #default="timeData">
              <span class="block">{{timeData.seconds}}</span>
            </template>
          </van-count-down>
        </template>
        <div slot="label" class="whitecolor">{{$t('NOTE')}}</div>
      </van-field> -->
      <div class="subbox">
        <van-button class="sub" round block type="default" native-type="submit">{{$t('REGISTER')}}</van-button>
      </div>
      <!-- <van-image class="logoimg" :src="require('./img/qdlogo.jpg')" /> -->
    </van-form>
    <van-popup v-model="areaCodeShow" style="width:100%">
      <AreaCode  :countryCode.sync="areaCode" :shows.sync="areaCodeShow" :ListData="ListData"></AreaCode>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { register, send } from '@/api/login'
import './css/index.css'
import axios from 'axios'
import AreaCode from './components/area.vue'
export default {
  name: 'register',
  data () {
    return {
      username: '',
      password: '',
      sms: '',
      empty: true,
      time: 5 * 60 * 1000,
      phoneNum: '',
      checkpassword: '',
      // 显示区号
      areaCodeShow: false,
      // 当前选择的区号
      areaCode: {
        name: 'Thailand',
        code: '66',
        pinyin: 'Thailand'
      },
      // 国家
      ListData: {}
    }
  },
  methods: {
    async onSubmit (values) {
      // values.message &&
      if (values.password && values.phoneNum && values.username && !(/[\u4E00-\u9FA5]/g.test(values.username))) {
        // 调用接口
        if (values.password !== this.checkpassword) {
          Toast(this.$t('TWICE'))
          return false
        }
        const { data } = await register({
          // captcha: values.message,
          name: values.username,
          password: values.password,
          phone: values.phoneNum,
          agent: document.domain
          // smsCode: this.sms
        })
        console.log(data)
        // 成功
        if (data.code === 200) {
          this.$store.commit('setToken', data.data)
          Toast(this.$t('REGISTEROK'))
          // this.$router.push({
          //   name: 'login'
          // })
          // Toast.loading({
          //   message: this.$t('AWAIT'),
          //   forbidClick: true,
          //   loadingType: 'spinner'
          // })
          setTimeout(() => {
            this.$router.push({
              name: 'home'
            })
          }, 3000)
        }
        if (data.code === 500) {
          Toast(data.message)
        }
      } else {
        Toast(this.$t('NOTNULL'))
      }
      console.log('submit', values)
    },
    // 获取国家
    async getRestcountries () {
      if (localStorage.getItem('countryData')) {
        this.ListData = JSON.parse(localStorage.getItem('countryData'))
      }
      const { data } = await axios.get('https://restcountries.com/v2/all')
      // console.log(data)
      const filteredData = data.map(country => {
        return {
          name: country.name,
          code: country.callingCodes[0],
          flag: country.flags.png,
          pinyin: country.name
        }
      })
      const sortedData = {}
      filteredData.forEach(country => {
        const firstLetter = country.name.charAt(0).toUpperCase()
        if (!sortedData[firstLetter]) {
          sortedData[firstLetter] = []
        }
        sortedData[firstLetter].push(country)
      })
      this.ListData = sortedData
      localStorage.setItem('countryData', JSON.stringify(this.ListData))
      console.log(sortedData)
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    async sendAuthCode () {
      if (!this.phoneNum) {
        Toast(this.$t('NOTNULL'))
        return false
      }
      const { data } = await send({
        phone: this.phoneNum,
        area: this.areaCode.code
      })
      console.log(data)
      this.empty = false
    },
    changeEmpty () {
      this.empty = true
    },
    getQueryString (name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) {
        return unescape(r[2])
      }
      return null
    }
  },
  created () {
    this.getRestcountries()
  },
  components: {
    AreaCode
  }
}
</script>

<style scoped>
.register >>> .van-field__control  {
  color: #fff;
}
.area-container >>> .van-field__label {
  width: 140px;
}
.area {
  border: 1px solid #fff;
  padding: 5px;
  border-radius: 5px;
}
.font15 {
  font-size: 14px!important;
}
.block {
  width: 60px !important;
  height: 31.98px;
  display: block;
  width: 22px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  background-color: #ff976a;
  line-height: 30px;
}
.prefix {
  color: red;
  margin-left: 20px;
}
.register {
  background: #FF910B;
  height: 100%;
  overflow-y: auto;
}
.logoimg {
  margin-top: 20px;
  height: 150px;
  width: 100%;
}
.seg {
  margin: 0 30px;
}
.subbox {
  margin: 30px 16px;
  margin-bottom: 10px;
}
.sub {
  font-size: 17px;
}
.formt {
  margin-top: 10px;
  /* padding: 20px; */
}
.yanzhengma {
  width: 60px;
  height: 100%;
}
.bar {
  overflow: hidden;
  margin-top: 20px;
}
.box {
  float: left;
  margin-left: 30px;
}
.user {
  float: right;
  font-size: 17px;
  margin-right: 30px;
  color: #fff;
}
.adv {
  padding: 10px;
  display: flex;
  justify-content: center;
}
</style>
