import request from '@/utils/request'
// /user/register注册
export const register = data => {
  return request({
    method: 'post',
    url: '/user/register',
    data
  })
}

// /sms/send发送短信
export const send = data => {
  return request({
    method: 'post',
    url: '/user/sendSmsCode',
    params: data
  })
}
// /user/login登录
export const login = data => {
  return request({
    method: 'post',
    url: '/user/login',
    params: data
  })
}
// /user/reset忘记密码
export const reset = data => {
  return request({
    method: 'post',
    url: '/user/reset',
    data
  })
}
