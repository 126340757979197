
<template>
  <div class="areacontainer">
    <van-nav-bar title="Select Country" left-arrow @click-left="onClickLeft"/>
    <van-search class="search" v-model="countryName" />
    <van-index-bar>
       <!--循环数据展示-->
      <div v-for="(list, key) of ListDatas" :key="key">
        <van-index-anchor :index="key" />
        <div v-for="item of list" :key="item.name">
          <van-cell
            center
            :value="item.code"
            @click.stop="handleItemClick(item)"
          >
          <template #title>
             <div class="title">
              <van-image :src="item.flag" width="20" height="20"></van-image>
              <span>{{item.name}}</span>
             </div>
          </template>
          </van-cell>
        </div>
      </div>
    </van-index-bar>
  </div>
</template>

<script>
export default {
  name: 'index',
  props: {
    ListData: {
      type: Object
    },
    /*
     * @description 国际区号
     * */
    countryCode: {
      type: Object
    },
    // 点击后隐藏属性
    shows: {
      type: Boolean
    }
  },
  components: {
  },
  data () {
    return {
      FirstPin: [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z'
      ],
      // 搜索名称
      countryName: '',
      // 选中显示
      code: {}
    }
  },
  methods: {
    // 头部返回事件
    onClickLeft () {
      this.$emit('update:shows', false)
    },
    // 点击事件
    handleItemClick (obj) {
      // 更改父组件的值
      this.$emit('update:countryCode', obj)
      this.$emit('update:shows', false)
    }
  },
  computed: {
    // 计算属性模糊搜索
    ListDatas () {
      if (this.countryName) {
        const newArr = {}
        const searchName = this.countryName.toLowerCase() // 转换为小写以进行不区分大小写匹配
        this.FirstPin.forEach((item) => {
          if (this.ListData[item]) {
            this.ListData[item].forEach((list) => {
              const lowerCaseName = list.name.toLowerCase() // 转换为小写以进行不区分大小写匹配
              if (
                lowerCaseName.indexOf(searchName) !== -1 ||
            list.code.indexOf(searchName) !== -1 // 搜索区域的代码
              ) {
                const a = lowerCaseName.charAt(0).toUpperCase() // 首字母大写
                if (newArr[a]) {
                  newArr[a].push(list)
                } else {
                  newArr[a] = []
                  newArr[a].push(list)
                }
              }
            })
          }
        })
        return newArr
      } else {
        return this.ListData
      }
    }
  },
  mounted () {},
  created () {
  }
}
</script>

<style scoped>
.title {
  display: flex;
  align-items: center;
}
.title span {
  padding-left: 5px;
}
.search >>> .van-field__control{
  color: #000 !important;
}
.areacontainer >>>.van-index-bar__index {
  padding: 0 2.133vw 0 4.267vw;
  font-weight: 500;
  font-size: 2.667vw;
  line-height: 5.733vw;
}
.areacontainer >>> .van-index-anchor {
  text-align: center;
  z-index: 1;
  box-sizing: border-box;
  padding: 0 4.267vw;
  color: #323233;
  font-weight: 500;
  font-size: 3.733vw;
  line-height: 8.533vw;
  background-color: #f0f6fb;
}
.areacontainer >>> .van-cell {
  border-bottom: 1px solid #fafafa;
}
.areacontainer >>> .van-search {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    box-sizing: border-box;
    padding: 2.667vw 7.2vw;
    background-color: #fff;
}
</style>
